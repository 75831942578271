import React from 'react'
import Banner3 from '../Componets/DCP UPS/Banner3'
import Section from '../Componets/DCP UPS/Section'
import Ups from '../Componets/DCP UPS/Ups.jsx'

function DCPUPS() {
    return (
        <>
            <Banner3 />
            <Section />
            <Ups />
        </>
    );
}

export default DCPUPS
