import React from 'react'
import Banner1 from '../Componets/About us/Banner1'
import AboutSection from '../Componets/About us/AboutSection'
import Ourmisson from '../Componets/About us/Ourmisson'
import OurValues from '../Componets/About us/OurValues'
import Banner2  from '../Componets/About us/Banner2'

const About1 = () => {
  return (
    <>
    <Banner1/>
    <AboutSection/>
    <Ourmisson/>
    <OurValues/>
    <Banner2/>
    </>
  )
}

export default About1
