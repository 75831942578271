import React from 'react'

const Came2Spec = () => {
  return (
    <div>
      <div className="container py-16 ">

      <h1 className="text-6xl font-bold text-center py-6 uppercase">Specification</h1>
        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Image Sensor
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">1/2.7"CMOS</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Resolution
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">2MP</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Image Size
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              1920 x 1080
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Video Output
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              AHD/TVI/CVI/CVBS
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Image System
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">PAL/NTSC</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Electronic Shutter
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
            Auto; 1 / 50s ~ 1 / 100000s ( PAL ), 1 / 60s ~ 1 / 100000s ( NTSC )
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
            IR Distance
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
            20 ~30 m
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
            Frame Rate
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
            30fps(60Hz); 25fps (50Hz)
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
            Min. Illumination
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
            0 lux (IRON)
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
            Lens
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
            2.8 mm
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
            Lens Mount
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
            M12
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
            S/N Ratio
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
            ≥52dB ( AGC OFF )
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
            Ingress Protection
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
            IP67
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Came2Spec
