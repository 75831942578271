import React from "react";

const Came3Spec = () => {
  return (
    <div>
      <div className="container py-16 ">
        <h1 className="text-6xl font-bold text-center py-6 uppercase">
          Specification
        </h1>
        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Analog Input
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">BNC × 4</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Resolution
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              TVI/AHD/CVI : 1080P/1080PLite/720P/WD1
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              IP Input
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              2CH (Up to 6CH)
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Total Input
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">6CH</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Video Output
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  pt-6 px-5 text-[20px]">
              HDMI × 1 : 1920 × 1080 / 1280 × 1024 / 1024 × 768
            </p>
            <p className=" text-black m-0  pb-6 px-5 text-[20px]">
              VGA × 1 : 1920 × 1080 / 1280 × 1024 / 1024 × 768
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Audio Input
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">RCA × 1</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Audio Output
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              RCA × 1 (linear, 600Ω)
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Video Compression
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              H.264/H.265
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Audio Compression
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">G.711(U/A)</p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Frame Rate
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              Analog Channel: 1080P Lite/720P/WD1 : 25fps (PAL)/30fps (NTSC) IP
              Channel: 25fps (PAL)/30fps (NTSC)
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Storage
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              SATA × 1, up to 6TB
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Bitrate
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              32Kbps ~ 4Mbps
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Incoming Bandwidth
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">
              10Mbps (Max. 30Mbps)
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 border-t-4 border-slate-300">
          <div className=" m-0 py-5 px-5">
            <p className="font-bold text-black m-0  py-5 px-5 text-3xl ">
              Outgoing Bandwidth
            </p>
          </div>
          <div className=" m-0 py-5 px-5">
            <p className=" text-black m-0  py-6 px-5 text-[20px]">30Mbps</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Came3Spec;
