import React from 'react'
import Banner4 from '../Componets/DCP CCTV/Banner4'
import Section2 from '../Componets/DCP CCTV/Section2'
import CCtv from '../Componets/DCP CCTV/Cctv'

const Cctv = () => {
  return (
    <>
      <Banner4/>
      <Section2/>
      <CCtv/>
    </>
  )
}

export default Cctv